import React from 'react';
import styled from 'styled-components';

export const Option = styled.li `
  text-align: left;
  padding: .25rem 0;
`

const Menu = styled.ul`
  position: absolute;
  top: 1rem;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 150%;
  height: 0;
  overflow: hidden;
  background-color: #fff;
  margin-top: .5rem;
  font-size: .8rem !important;
`;

const MainCont = styled.div`
  //display: inline-flex;
  //flex-direction: column;
  position: relative;
  &:hover{
    cursor: pointer;
    ${Menu}{
      height: auto;
      padding: .25rem 0;
      padding-left: .5rem;
      box-shadow: 0px 4px 15px  rgba(0, 0, 0, .25);
    }
  }
`

const Title = styled.span`

`



export default ({ title, children })=> {

  return(
    <MainCont>
      <Title>
        {title}
      </Title>
      <Menu>
        {children}
      </Menu>      
    </MainCont>
  )
}