import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { LoadingOutlined } from '@ant-design/icons';

const MainCont = styled.div`
  padding: 0;
  user-select: none;
  background-color: transparent;
  @media(min-width: 992px){
    background-color: ${props => props.theme.primaryColor}
  }
`
const RatesCont = styled.ul`
  list-style: none;
  padding: .5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1rem;
  @media(min-width: 992px){
    color: #fff;
    font-weight: normal;
    justify-content: flex-end;
    //color: ${props => props.theme.primaryColor};
    margin: 0;
    font-size: .7rem;
  }
`
const RateItem = styled.li`
  //color: ${props => props.theme.primaryColor};
  user-select: none;
  margin-left: .30rem;
  &::after{
    content: " -"
  }
  @media(min-width: 768px){
    margin-left: .5rem;
    &::after{
      content: " /"
    } 
  }
`
const RateItemNoAfter = styled(RateItem)`
  &::after{
    content: ""
  }
  @media(min-width: 768px){
    &::after{
      content: ""
    } 
  }
`

export default ()=> {
  const [query, setQuery] = useReducer((current, next) => ({ ...current, ...next }), {
    data: null,
    error: false,
    loading: true,
  });

  const getData = async(url)=>{
    try{
      const data = await fetch(url);
      const result = await data.json();
      return result;
    }
    catch(e){
      console.log(e);
    }
  };

  const getAllData = async()=> {
    try{
      const url = "https://api.clasihome.com/rest/indicators";
      //const url = "http://localhost:3000/dev/indicators";
      const data = await fetch(url);
      const indicators = await data.json();
      setQuery({ loading: false, error: false, data: indicators }); 
    }
    catch(e){
      console.log(e);
      setQuery({ loading: false, error: true, data: null });
    }
  };

  useEffect(()=>{
    if(window !== "undefined"){
      getAllData();
    }
  },[]);

  if(query.loading) return(
    <MainCont>
      <Container>
        <RatesCont>
          <RateItem>
            UF <span><LoadingOutlined /></span>
          </RateItem>
          <RateItem>
            UTM <span><LoadingOutlined /></span>
          </RateItem>
          <RateItemNoAfter>
            Dólar <span><LoadingOutlined /></span>
          </RateItemNoAfter>                    
        </RatesCont>
      </Container>
    </MainCont>
  );

  if(query.error)   return(
    <MainCont>
      <Container>
        <RatesCont>
          <RateItem>
            UF 0
          </RateItem>
          <RateItem>
            UTM 0
          </RateItem>
          <RateItemNoAfter>
            Dólar 0
          </RateItemNoAfter>                    
        </RatesCont>
      </Container>
    </MainCont>
  )

  return(
    <MainCont>
      <Container>
        <RatesCont>
          <RateItem>
            UF {query.data.uf}
          </RateItem>
          <RateItem>
            UTM {query.data.utm}
          </RateItem>
          <RateItemNoAfter>
            Dólar {query.data.dollar}
          </RateItemNoAfter>                    
        </RatesCont>
      </Container>
    </MainCont>
  )
}